.arrow_upward {
  content: url('../img/arrow_upward.svg');
  height: 25px;
  width: 25px;
}

.arrow_downward {
  content: url('../img/arrow_downward.svg');
  height: 25px;
  width: 25px;
}

.difficulty_option {
  align-content: center;
  border: solid 1px #777777;
  cursor: pointer;
  display: grid;
  font-family: 'Open Sans';
  font-size: 18px;
  font-weight: 200;
  justify-content: center;
  margin-top: 2px;
  padding: 5px;
  transition: all 0.1s ease-out;
}

.difficulty_option:hover {
  color: white;

  background-color: #0054a6;
  border: solid 1px #333333;

  filter: brightness(95%);
}

.difficulty_option_selected {
  color: white;

  background-color: #0054a6;
  border: solid 1px #333333;

  filter: brightness(95%);
}

#footer_items {
  margin-bottom: 0;

  display: grid;
  grid-column: 2;
  grid-template-columns: 4% auto 4%;

  align-items: center;
  justify-items: center;
}

#footer {
  grid-column: 2 / 2;
}

.message {
  margin: 8px;
  color: #737373;
}

.result_item_title {
  text-align: center;
}

.result_item_title a {
  color: #00478f;
  text-decoration: none;
}

.search_filter {
  display: inline-block;
  padding: 15px 15px 15px 15px;
}

.search_filter_title {
  font-weight: bolder;
  margin-bottom: 5px;
}

.search_result {
  grid-column: 1;
  display: grid;

  align-items: center;
  justify-items: center;

  grid-template-columns: 50px repeat(7, 1fr);

  background: #f9f9f9;

  border: solid 0.5px lightgrey;

  grid-auto-rows: 150px;
}

.sub_description {
  display: block;

  text-align: center;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #a4a4a4;
  font-size: 17px;
}

#text_search {
  display: grid;

  grid-row-gap: 35px;
  row-gap: 35px;

  grid-column-gap: 15px;
  column-gap: 15px;

  font-family: 'Open Sans';
  font-size: 18px;
  font-weight: 200;

  margin-top: 60px;

  grid-template-columns: 4% auto 4%;
}

#text_search_filters {
  grid-column: 2;

  border: solid 0.5px lightgrey;
  background: white;

  padding: 10px 10px 10px 10px;

  grid-row-gap: 15px;
  row-gap: 15px;
}

#text_search_filters_label {
  font-weight: bolder;
}

#text_search_help_msg > div {
  margin-top: 5px;
}

#text_search_results {
  grid-column: 2;

  display: grid;

  grid-row-gap: 20px;
  row-gap: 20px;
}

#text-search-welcome-message {
  display: grid;

  grid-column: 2;

  justify-content: center;

  padding: 10px;
  border: 1px solid #0054a6;
  border-radius: 5px;
  font-size: 20px;
}

#text-search-welcome-message a {
  color: #00478f;
  text-decoration: none;
}

#text-search-welcome-message a:hover {
  text-decoration: underline;
}

#text_search_btn {
  display: inline-block;
  cursor: pointer;
  background: white;

  padding: 15px 15px 15px 15px;
}

#text_search_footer {
  grid-column: 2;
}

.text_status {
  display: grid;
  cursor: pointer;

  border: solid 1px #777777;

  margin-top: 2px;
  padding: 5px 5px 5px 5px;

  align-content: center;
  justify-content: center;

  transition: all 0.1s ease-out;
}

.text_status_option_selected {
  color: white;

  background-color: #0054a6;
  border: solid 1px #333333;

  filter: brightness(95%);
}

.text_status:hover {
  color: white;

  background-color: #0054a6;
  border: solid 1px #333333;

  filter: brightness(95%);
}

.text_tag {
  cursor: pointer;
  margin: 5px 5px 5px 5px;

  transition: all 0.05s ease-out;
}

.text_tag:hover {
  color: white;

  background-color: #0054a6;
  border: solid 1px #333333;

  filter: brightness(95%);
}

.text_tag_selected {
  color: white;

  background-color: #0054a6;
  border: solid 1px #333333;

  filter: brightness(95%);
}

.vote-enabled {
  cursor: pointer;
}

.vote-disabled {
  pointer-events: none;
}

@media only screen and (max-width: 480px) {
  .difficulty_option {
    display: flex;
  }

  .filter {
    display: none;
  }

  .header {
    display: flex;
  }

  .search_filter {
    padding: 15px 0 0 0;
  }

  #text_tag_search {
    width: 250px;
  }

  #text_search_filters {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .menu {
    display: flex;
    flex-wrap: wrap;

    align-items: center;
    justify-items: center;
  }

  #text_search_results {
    grid-column: 2;
    display: grid;

    grid-row-gap: 20px;
    row-gap: 20px;
  }

  .search_result {
    grid-column: 1;
    display: flex;
    flex-direction: column;

    font-family: 'Open Sans';
    font-size: 20px;

    padding: 15px 15px 15px 15px;

    background: white;

    border: solid 0.5px lightgrey;
  }
}
